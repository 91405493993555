import { SanityVideo, SanityVideoType } from '@data/sanity/queries/types/video'

import MuxVideo from '@components/video/mux-video'
import VimeoVideo from '@components/video/vimeo-video'

interface VideoModuleProps extends SanityVideo {
  className?: string
}

const VideoModule = ({
  type,
  vimeoVideo,
  muxVideo,
  settings,
  className,
  aspectRatio,
}: VideoModuleProps) => {
  return (
    <section className={className}>
      {type === SanityVideoType.VIMEO && vimeoVideo && (
        <VimeoVideo video={vimeoVideo} />
      )}

      {type === SanityVideoType.MUX && muxVideo && (
        <MuxVideo
          video={muxVideo}
          showControls={settings?.controls}
          customControls={settings?.customControls}
          autoplay={settings?.autoplay}
          loop={settings?.loop}
          muted={settings?.muted}
          customAspectRatio={
            aspectRatio?.custom
              ? {
                  mobile: aspectRatio.mobile,
                  desktop: aspectRatio.desktop,
                }
              : null
          }
        />
      )}
    </section>
  )
}

export default VideoModule
